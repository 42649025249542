export const scrollAnimation = (currentY, targetY, element = window, needAnimation = true) => {
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY;
  let _currentY = currentY;
  if (needAnimation) {
    setTimeout(() => {
      // 一次调用滑动帧数，每次调用会不一样
      const dist = Math.ceil(needScrollTop/10);
      _currentY += dist;
      element.scrollTo(_currentY, currentY);
      // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
      if (needScrollTop > 10 || needScrollTop < -10) {
        scrollAnimation(_currentY, targetY, element,needAnimation);
      } else {
        element.scrollTo(_currentY, targetY);
      }
    }, 20)
  } else {
    element.scrollTo(_currentY, targetY);
  }
};

let scrollTimer = null;
export const scrollAnimationV2 = (targetY, element = window, needAnimation = true) => {
  if(scrollTimer) clearInterval(scrollTimer);
  // 计算需要移动的距离
  const currentY = element.scrollTop;
  let needScrollTop = targetY - currentY;
  let _currentY = currentY;
  let percent = 0.1,offP = 1.1;
  if (needAnimation) {
    // const step = Math.ceil(needScrollTop*percent);
    const step = Math.ceil(needScrollTop/10);
    scrollTimer = setInterval(()=>{
      _currentY = element.scrollTop + step;
      // console.log({_currentY,step,percent,jianju:(targetY-_currentY)})
      if(Math.abs(targetY-_currentY)<10){
        element.scrollTo(0, targetY);
        clearInterval(scrollTimer);
      }else{
        element.scrollTo(0, _currentY);
      }
      percent *= offP;
    },20)
  } else {
    element.scrollTo(0, targetY);
  }
};
export const scrollToDomTop = (dom, fatherScrollDom = window, needAnimation = true) => {
  const currentY = document.documentElement.scrollTop || document.body.scrollTop;
  if (dom) {
    scrollAnimation(currentY, dom.offsetTop, fatherScrollDom, needAnimation);
  }
};

export const scrollToDomBottom = (dom, fatherScrollDom = dom, needAnimation = true) => {
  const currentY = document.documentElement.scrollTop || document.body.scrollTop;
  if (dom) {
    scrollAnimation(currentY, dom.scrollHeight, fatherScrollDom, needAnimation);
  }
};
