import Browser from './../js/browser.js';

const browser = new Browser(),
	isPC = browser.device === 'PC';

/*
获取坐标
*/
const getTouchCoord = (e)=>{
	var e = e || window.event;
	const {clientY,clientX} = e.touches[0];
	return {x:clientX,y:clientY}
}

export const scroll = function(callback,dom=document){
	const that = this;
	let timer = null,touchS,touchE;
	const init = ()=>{
		if(isPC){
			if(document.addEventListener){
				//W3C
	            document.addEventListener('DOMMouseScroll',(e)=>{
	            	scrollFunc(e)
	            },false);
	        }
	        window.onmousewheel=document.onmousewheel=(e)=>{
	        	scrollFunc(e);
	        };//IE/Opera/Chrome
		}else{
			document.addEventListener('touchstart',touchstart,false);
		}
	}
	const touchstart = (e)=>{
		document.addEventListener('touchmove',touchmove,false);
		document.addEventListener('touchend',touchend,false);
		touchS = getTouchCoord(e);
	}
	const touchmove = (e)=>{
		touchE = getTouchCoord(e);
	}
	const touchend = ()=>{
		document.removeEventListener('touchmove',touchmove,false);
		document.removeEventListener('touchend',touchend,false);
		// console.log({touchE,touchS})
		if(!touchE||!touchS) return;
		if(touchE.y>touchS.y){
			callback(-1)
		}else if(touchE.y<touchS.y){
			callback(1);
		}
	}
	const onscroll_pc = (e,from)=>{
		if(timer){
			clearTimeout(timer);
		}
		timer = setTimeout(()=>{
			// 时长内只响应一次
			_callback(e);
		},200)
	}
	const _callback = (e)=>{
		if(e.deltaY>0){
			callback(1);
		}else if(e.deltaY<0){
			callback(-1);
		}
	}
	const scrollFunc = (e)=>{
		e=e || window.event;
        if(e.wheelDelta){//IE/Opera/Chrome
        	onscroll_pc(e,1)
        }else if(e.detail){//Firefox
        	//自定义事件：编写具体的实现逻辑
        	onscroll_pc(e,2)
        }
	}
	init();
};
