import React, {
	Component
} from 'react';
import LazyLoad from 'react-lazy-load';
import Browser from './../js/browser.js';
import {
	scroll
} from './../utils/scroll.js';
import {
	scrollAnimationV2
} from './../utils/animateUtil.js';
import logo from './../img/logo.png';
import img_newsBox from './../img/news.png';
import './App.css';

const browser = new Browser(),
	isPC = browser.device === 'PC';
// console.log(browser)
const headerHeight = isPC?100:140;
class App extends Component {
	constructor(props) {
		super(props);
		this.showWechat = this.showWechat.bind(this);
		this.hideWechat = this.hideWechat.bind(this);
		this.clickBody = this.clickBody.bind(this);
		this.state = {
			wechatDisplay: 'none',
			detailsHeight:600,
			curPage:1,
			a_wechat_pc: <a href="#" title="值班微信" onMouseOver={this.showWechat} onMouseOut={this.hideWechat}></a>,
			a_wechat_mobile: <a href="#" onClick={this.showWechat}></a>
		};
		this.onScroll = this.onScroll.bind(this);
	}
	componentWillMount() {
		const that = this;
		this.onResize();
		document.onresize = ()=>{
			that.onResize();
		}
		const {os} = browser;
		const _browser = browser.browser;
		if(os=='Android'&&_browser=='Wechat') {
			// 安卓微信不滚
			document.body.style.overflowY='auto';
		}else{
			scroll(this.onScroll);
		}
	}
	onScroll(dicrect=1){
		/*
		1 向下
		-1 向上
		*/
		const winWidth = window.innerWidth;
		const winHeight = window.innerHeight;
		let top = document.body.scrollTop;
		const {detailsHeight} = this.state;
		let page = Math.ceil((top - headerHeight)/winHeight)+1;
		if(page<=1&&dicrect<0) return;
		if(page>=4&&dicrect>0) return;
		let offy = detailsHeight*dicrect;
		let startTop = headerHeight+(page-1)*detailsHeight;
		// startTop = page==2&&dicrect<0?0:startTop;
		let value = page==2&&dicrect<0?0:(startTop+offy);
		
		// console.log({os,_browser,page,value,offy,dicrect,startTop})
		this.setState({
			curPage:page+dicrect
		})
		scrollAnimationV2(value,document.body,true);
	}
	createHtml(index){
		if(index==1){
			return <><div className="left box-border">
						<img src="./img/img1.png" alt=""/>
					</div>
					<div className="right box-border">
						<h2>专注前端开发</h2>
						<h2 className="eng">Concentrate on Front-end Outsource</h2>
						<p>专业专注、零风险<span>迭代、维护便捷</span></p>
						<p>6个月免费维护<span>24小时快速响应，紧急事件3小时响应</span></p>
						<p>永久维护，永不放弃<span>杜绝维护找人难，上手慢</span></p>
					</div></>
		}else if(index==2){
			return <><div className="left box-border">
						<LazyLoad offsetVertical={6000}><img src="./img/img2.png" alt=""/></LazyLoad>
					</div>
					<div className="right box-border">
						<h2>值得信赖的工程师操守</h2>
						<h2 className="eng">Reliable Engineer</h2>
						<p>趁手的开发工程师</p>
						<p>合作时间灵活<span>可远程、可见面、可坐班、可出差</span></p>
						<p>大厂开发标准<span>文档规范、技术最in</span></p>
					</div></>
		}else if(index==3){
			return <><div className="left box-border">
						<LazyLoad offsetVertical={3000}><img src="./img/img3.png" alt=""/></LazyLoad>
					</div>
					<div className="right box-border">
						<h2>多平台支持</h2>
						<h2 className="eng">Multi-platform</h2>
						<p>Web网页<span>电脑端、移动端（手机、平板）</span></p>
						<p>小程序、公众号<span>支付宝、微信</span></p>
						<p>移动应用Apps<span> Android、IOS</span></p>
					</div></>
		}else if(index==4){
			return <><div className="left box-border">
						<LazyLoad offsetVertical={3000}><img src="./img/img4.png" alt=""/></LazyLoad>
					</div>
					<div className="right box-border">
						<h2>最新最全的技术</h2>
						<h2 className="eng">All top technology</h2>
						<p>jquery bootstrap html5</p>
						<p>vue react angular<span>es6 typescript less/sass </span></p>
						<p>svg canvas webgl</p>
						<p>webpack<span>node.js</span></p>
					</div></>
		}
		return null;
	}
	onResize(){
		console.log('resize...')
		const winWidth = window.innerWidth;
		const winHeight = window.innerHeight;
		const body = document.body;
		body.className = winWidth < 1100?'body-fit':'';
		let _height = winHeight - headerHeight;
		this.setState({
			detailsHeight:_height
		})
	}
	showWechat() {
		if (!isPC) console.log('click');
		this.setState({
			wechatDisplay: 'block'
		});
	}
	hideWechat() {
		this.setState({
			wechatDisplay: 'none'
		});
	}
	clickBody() {
		if (!isPC && this.state.wechatDisplay === 'block') {
			this.hideWechat();
		}
	}
	render() {
		const {
			detailsHeight,
			curPage
		} = this.state;
		// console.log({curPage})
		let newsBox_bottom = !isPC && curPage<=1?0:(headerHeight+30);
		return (
			<div className="ctn-app" onClick={this.clickBody}>
				<div className={'ctn-wechat '+(isPC?'':'in-mobile')} style={{'display':this.state.wechatDisplay}}>
					<img src="./img/wechat.png" alt="微信值班工程师：kingmailab" />
					<p>{isPC?'扫一扫上面的二维码图案':'长按二维码保存，到微信内扫一扫'}<br/>加值班工程师微信</p>
				</div>
				<div className="news-box" style={{bottom:newsBox_bottom+'px',display:'none'}}>
					<div className="title">
						<img src={img_newsBox} alt="" />
						<div>最新动态</div>
					</div>
					<div className="sub-title" onClick={()=>{window.open('/5s/beginning.html')}}>
						<span>新教程：</span>5分钟学前端系列教程之入门
					</div>
				</div>
				<header className="absolute">
					<div className="logo">
						<a href="/"><img src={logo} alt="青麦IT"/></a>
						<a href="/"><h1>青麦网络信息技术 ( IT ) 实验室</h1></a>
					</div>
					<div className="connect">
						{browser.device=="PC"?this.state.a_wechat_pc:this.state.a_wechat_mobile}
						<a title="值班QQ" href="http://wpa.qq.com/msgrd?v=3&uin=2689335720&site=qq&menu=yes" target="_blank"></a>
						<a title="官方Email" href="mailto:2689335720@qq.com"></a>
						<a title="Github" href="https://github.com/vee2046" target="_blank"></a>
					</div>
				</header>
				{
					[1,2,3,4].map((html,index)=>(
						<div 
							key={index}
							className={`absolute details  p${index+1}`}
							style={{height:detailsHeight+'px',marginBottom:0}}
							>
							{this.createHtml(index+1)}
						</div>
					))
				}
				<footer>
				{/*http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001*/}
					<a className="beian" href="https://beian.miit.gov.cn/" target="_blank"><i></i>京ICP备13018113号-1</a>
				</footer>
			</div>
		);
	}
}

export default App;